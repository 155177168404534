import { makeStyles } from '@material-ui/core/styles';

const drawerComponentStyle = makeStyles((theme) => ({
	toolbar: theme.mixins.toolbar,
	logo: {
		maxWidth: 200,
		marginTop: -75,
		paddingTop: 0
	},
	operatorLogo: {
		maxWidth: 75,
		marginTop: 15,
		marginLeft: 15,
		paddingTop: 0
	}
}));

export default drawerComponentStyle;
