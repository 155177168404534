import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const appBarStyle = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	appBar: {
		marginLeft: drawerWidth,
		[theme.breakpoints.up('sm')]: {
			width: `calc(100% - ${drawerWidth}px)`
		}
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none'
		}
	},
	title: {
		flex: 1
	},
	link: {
		'&:hover': {
			color: 'inherit'
		}
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0
		}
	},
	drawerPaper: {
		width: drawerWidth
	},
	logo: {
		maxWidth: 200
	}
}));

export default appBarStyle;
