import { makeStyles } from '@material-ui/core/styles';
import { auto } from 'async';

const solutionSearchStyle = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		flexGrow: 1,
		width: '100%'
	},
	searchPaper: {
		padding: theme.spacing(2),
		width: '100%',
		marginBottom: theme.spacing(2)
	},
	paper: {
		margin: theme.spacing(2),
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column'
	},
	body2: {
		margin: theme.spacing(1)
	},
	fab: {
		marginLeft: theme.spacing(1)
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		minWidth: 300,
		[theme.breakpoints.up('sm')]: {
			minWidth: 250
		},
		[theme.breakpoints.up('md')]: {
			minWidth: 400
		}
	},
	textFieldSearch: {
		minWidth: 200,
		[theme.breakpoints.up('sm')]: {
			minWidth: 200
		},
		marginRight: theme.spacing(1)
	},
	listSubHeaderCustom: {
		marginBottom: 10
	},
	listItemCustom: {
		marginTop: -15,
		marginLeft: 15,
		padding: 0,
		[theme.breakpoints.up('lg')]: {
			marginTop: -20,
			marginLeft: 15,
			padding: 0
		}
	}
}));

export default solutionSearchStyle;
