import { makeStyles } from '@material-ui/core/styles';

const documentStyle = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3, 2),
		width: '100%'
	},
	divider: {
		marginTop: 0,
		marginBottom: 5,
		marginLeft: 0,
		marginRight: 0,
		background: '#fff'
	},
	snackbarWidth: {
		minWidth: 700,
		maxWidth: 700
	},
	icon: {
		marginTop: 5
	},
	margin: {
		margin: theme.spacing(1)
	},
	body2: {
		margin: theme.spacing(1)
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 150
	},
	input: {
		marginLeft: 8,
		flex: 1
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 400
	},
	noData: {
		marginTop: 25
	},
	textFieldSearch: {
		minWidth: 100,
		[theme.breakpoints.up('sm')]: {
			minWidth: 100
		},
		marginRight: theme.spacing(1)
	},
	table: {
		marginTop: theme.spacing(1),
		width: '100%',
		overflowX: 'auto',
		marginBottom: theme.spacing(2)
	},
	btnAdd: {
		paddingTop: 0,
		paddingBottom: 0,
		paddingRight: 10,
		marginRight: 10,
		'&:hover': {
			color: '#fff'
		}
	},
	btnNewText: {
		paddingTop: 5,
		paddingBottom: 0
	},
	btnEdit: {
		marginBottom: 0,
		marginTop: 1,
		padding: 5,
		color: '#1E2228'
	},
	marginTop: {
		marginTop: 25
	},
	modal: {
		marginTop: 300,
		margin: 'auto',
		width: '100%',
		position: 'absolute',
		backgroundColor: '#FB713F',
		padding: theme.spacing(2, 4, 3),
		color: '#fff',
		textAlign: 'center'
	},
	logo: {
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		},
		maxWidth: 200
	},
	contentBody: {
		margin: 8
	},
	dividerMarginBottom: {
		marginBottom: 10,
		width: '95%'
	},
	tableLink: {
		cursor: 'pointer',
		'&:hover': {
			color: '#18bef5'
		}
	},
	textLink: {
		textDecoration: 'none !important',
		'&:hover': {
			color: '#18bef5',
			textDecoration: 'none !important'
		}
	},
	cardHeaderForDocumentsBlueBckg: {
		backgroundColor: '#0080c3',
		color: '#f8f8f8',
		height: 50
	},
	cardHeaderForDocumentsGreenBckg: {
		backgroundColor: '#2ebc19',
		color: '#f8f8f8',
		height: 50
	},
	cardHeaderText: {
		margin: 8
	},
	helperText: {
		color: '#d8d8d8'
	},
	documentContentMargin: {
		marginTop: 25
	}
}));

export default documentStyle;
