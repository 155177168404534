import React, { useReducer } from 'react';
import axios from 'axios';
import DocumentContext from './documentContext';
import documentReducer from './documentReducer';
import Cookies from 'js-cookie';
import fileDownload from 'js-file-download';
import {
	SET_LOADING,
	SET_IS_PROCESSING,
	SET_PROCESSING_SUCCESS,
	SET_PROCESSING_ERROR,
	SET_VISIBLE_COMPONENT,
	SET_PAGE_TITLE,
	SET_SELECTED_ID,
	SET_TOTAL_RECORD_COUNT,
	SET_INITIALIZING,
	SET_DOCUMENT,
	SET_ENABLE_BUTTON,
	SET_SEARCH_RESULTS,
	SET_RESEARCH_DOCUMENTS,
	SET_SOLUTION_ID,
	SET_INTERNAL_SOLUTION_ID,
	SET_IS_SEARCHING,
	SET_DOCUMENT_CONTENT,
	SET_SEARCH_TEXT,
	SET_IS_DOWNLOADING,
	SET_SEARCH_QUERY,
	SET_VALID_FILE
} from './documentType';
import moment from 'moment';

// Setup config/headers and token
const headerConfig = () => {
	// Headers
	const config = {
		headers: {
			'Content-type': 'application/json',
			'csrf-token': Cookies.get('XSRF-TOKEN'),
			Authorization: 'Bearer ' + localStorage.getItem('_mhcms')
		},
		withCredentials: true
	};

	return config;
};

//	const apiHost = 'http://localhost:5000';
const apiHost = 'https://prod.api.microscopehc.solvaris.net';

const DocumentState = (props) => {
	const initialState = {
		documentProcessing: null,
		processingError: null,
		isSearching: null,
		searchQuery: null,
		totalDocumentCount: null,
		enableSubmitBtn: false,
		validFile: null,
		document: null,
		searchText: null,
		searchResults: null,
		documentContent: null,
		isInitializing: true,
		solutionId: null,
		internalSolutionId: null,
		isDownloading: false
		// enableSubmitBtn: false,
		// isInitializing: true,
		// isLoading: null,
		// isSearching: null,
		// processingSuccess: null,
		// processingError: null,
		// document: null,
		// selectedId: null,
		// visibleComponent: null,
		// totalDocumentCount: null,
		// pageTitle: 'Documents',
		// searchText: null,
		// searchResults: null,
		// researchDocuments: null,
		// solutionId: null,
		// internalSolutionId: null,
		// documentContent: null,
		// searchQuery: null
	};

	const [ state, dispatch ] = useReducer(documentReducer, initialState);

	// Get total number of documents
	const getTotalDocuments = async () => {
		axios.get(apiHost + '/document/recordcount', headerConfig()).then((res) => {
			dispatch({
				type: SET_TOTAL_RECORD_COUNT,
				payload: res.data.data
			});
		});
	};

	const setSearchQuery = async (form) => {
		let body = {};
		if (form != null) {
			body = JSON.stringify(form);
		}
		dispatch({
			type: SET_IS_SEARCHING,
			payload: true
		});

		if (body.length < 1) {
			dispatch({
				type: SET_SEARCH_QUERY,
				payload: null
			});
		}

		axios.post(apiHost + '/document/recordcount', body, headerConfig()).then((res) => {
			dispatch({
				type: SET_TOTAL_RECORD_COUNT,
				payload: res.data.data
			});
			dispatch({
				type: SET_SEARCH_QUERY,
				payload: form
			});
			dispatch({
				type: SET_IS_SEARCHING,
				payload: false
			});
		});
	};

	// Set Is Searching
	const setSearching = async (value) => {
		dispatch({
			type: SET_IS_SEARCHING,
			payload: value
		});
	};

	// Upload Document
	const uploadDocument = async (form, file) => {
		form.solutionId = parseInt(form.solutionId);
		const fileName = form.fileName;
		const updatedFile = new File([ file ], fileName, {
			type: file.type
		});
		const formData = new FormData();
		formData.append('files', updatedFile);

		// Save form to db
		const body = JSON.stringify(form);
		axios
			.post(apiHost + '/document/', body, headerConfig())
			.then((res) => {
				if (res.data.success) {
					// Save document to server
					axios
						.post(apiHost + '/document/uploaddocument', formData, headerConfig())
						.then((res) => {
							if (res.data.success) {
								dispatch({
									type: SET_IS_PROCESSING,
									payload: 'done'
								});
							} else {
								dispatch({
									type: SET_PROCESSING_ERROR,
									payload: 'Error uploading file'
								});
								dispatch({
									type: SET_IS_PROCESSING,
									payload: 'done'
								});
							}
						})
						.catch((err) => {
							dispatch({
								type: SET_PROCESSING_ERROR,
								payload: 'Error uploading file'
							});
							dispatch({
								type: SET_IS_PROCESSING,
								payload: 'done'
							});
						});
				} else {
					dispatch({
						type: SET_PROCESSING_ERROR,
						payload: 'Error saving document.'
					});
					dispatch({
						type: SET_IS_PROCESSING,
						payload: 'done'
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: SET_PROCESSING_ERROR,
					payload: 'Error saving document.'
				});
				dispatch({
					type: SET_IS_PROCESSING,
					payload: 'done'
				});
			});
	};

	// Set Enable Submit Button
	const setEnableSubmitButton = async (value) => {
		dispatch({
			type: SET_ENABLE_BUTTON,
			payload: value
		});
	};

	// Validate File Extension
	const validateFileExtension = async (file) => {
		const fileName = 'testFile';
		const updatedFile = new File([ file ], fileName, {
			type: file.type
		});
		const formData = new FormData();
		formData.append('files', updatedFile);
		axios.post(apiHost + '/document/validate/extension', formData, headerConfig()).then((res) => {
			dispatch({
				type: SET_VALID_FILE,
				payload: res.data.success
			});
		});
	};

	// Get Document by Id
	const getDocumentById = async (id) => {
		axios.get(apiHost + '/document/get/id/' + id, headerConfig()).then((res) => {
			dispatch({
				type: SET_DOCUMENT,
				payload: res.data.data
			});
		});
	};

	// Clear Document
	const clearDocument = async () => {
		dispatch({
			type: SET_DOCUMENT,
			payload: null
		});
	};

	// Set Is Processing
	const setIsProcessing = async (value) => {
		dispatch({
			type: SET_IS_PROCESSING,
			payload: value
		});
	};

	// Download Document
	const downloadDocument = async (documentId, fileName) => {
		dispatch({
			type: SET_IS_DOWNLOADING,
			payload: true
		});
		fetch(apiHost + '/document/' + documentId + '/download/', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
				'csrf-token': Cookies.get('XSRF-TOKEN'),
				Authorization: 'Bearer ' + localStorage.getItem('_mhcms')
			}
		})
			.then((response) => response.blob())
			.then((blob) => {
				// Create blob link to download
				const url = window.URL.createObjectURL(new Blob([ blob ]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileName + `.docx`);

				// Append to html link element page
				document.body.appendChild(link);

				// Start download
				link.click();

				// Clean up and remove the link
				link.parentNode.removeChild(link);

				dispatch({
					type: SET_IS_DOWNLOADING,
					payload: false
				});
			});
	};

	// updateDocument
	const updateDocument = async (form, file, oldFileName) => {
		if (file.name != undefined) {
			const fileName = form.fileName;
			const updatedFile = new File([ file ], fileName, {
				type: file.type
			});
			const formData = new FormData();
			formData.append('files', updatedFile);
			// Save form to db
			form.oldFileName = oldFileName;
			form.solutionId = parseInt(form.solutionId);
			const body = JSON.stringify(form);
			axios
				.put(apiHost + '/document/', body, headerConfig())
				.then((res) => {
					// Save new file
					if (res.data.success) {
						// Save document to server
						axios
							.post(apiHost + '/document/uploaddocument/', formData, headerConfig())
							.then((res) => {
								if (res.data.success) {
									dispatch({
										type: SET_PROCESSING_SUCCESS,
										payload: 'Success'
									});
									dispatch({
										type: SET_IS_PROCESSING,
										payload: 'done'
									});
								} else {
									dispatch({
										type: SET_PROCESSING_ERROR,
										payload: 'Error uploading file'
									});
									dispatch({
										type: SET_IS_PROCESSING,
										payload: 'done'
									});
								}
							})
							.catch((err) => {
								dispatch({
									type: SET_PROCESSING_ERROR,
									payload: 'Error uploading file'
								});
								dispatch({
									type: SET_IS_PROCESSING,
									payload: 'done'
								});
							});
					} else {
						dispatch({
							type: SET_PROCESSING_ERROR,
							payload: 'Error saving document.'
						});
						dispatch({
							type: SET_IS_PROCESSING,
							payload: 'done'
						});
					}
				})
				.catch((err) => {
					dispatch({
						type: SET_PROCESSING_ERROR,
						payload: 'Error saving document.'
					});
					dispatch({
						type: SET_IS_PROCESSING,
						payload: 'done'
					});
				});
		} else {
			// Save form to db
			form.oldFileName = oldFileName;
			form.solutionId = parseInt(form.solutionId);
			const body = JSON.stringify(form);
			axios
				.put(apiHost + '/document/', body, headerConfig())
				.then((res) => {
					if (res.data.success) {
						dispatch({
							type: SET_PROCESSING_SUCCESS,
							payload: 'Success'
						});
						dispatch({
							type: SET_IS_PROCESSING,
							payload: 'done'
						});
					} else {
						dispatch({
							type: SET_PROCESSING_ERROR,
							payload: 'Error saving document.'
						});
						dispatch({
							type: SET_IS_PROCESSING,
							payload: 'done'
						});
					}
				})
				.catch((err) => {
					dispatch({
						type: SET_PROCESSING_ERROR,
						payload: 'Error saving document.'
					});
					dispatch({
						type: SET_IS_PROCESSING,
						payload: 'done'
					});
				});
		}
	};

	// Search Documents
	const searchDocuments = async (param) => {
		axios.get(apiHost + '/document/' + param.search, headerConfig()).then((res) => {
			dispatch({
				type: SET_SEARCH_RESULTS,
				payload: res.data.data
			});
			setSearching(false);
		});
	};

	// Clear Solution Id
	const clearSolutionId = async () => {
		dispatch({
			type: SET_SOLUTION_ID,
			payload: null
		});
	};

	// Clear Search Results
	const clearSearchResults = async (param) => {
		dispatch({
			type: SET_SEARCH_RESULTS,
			payload: null
		});
	};

	// Set Document Content
	const setDocumentContent = async (solutionId, internalSolutionId, content) => {
		if (content != null) {
			if (content != null) {
				content = content.replace('[image: ]', '');

				dispatch({
					type: SET_DOCUMENT,
					payload: null
				});
				dispatch({
					type: SET_INTERNAL_SOLUTION_ID,
					payload: internalSolutionId
				});
				dispatch({
					type: SET_SOLUTION_ID,
					payload: solutionId
				});
				dispatch({
					type: SET_DOCUMENT_CONTENT,
					payload: content
				});
				dispatch({
					type: SET_LOADING,
					payload: false
				});
				dispatch({
					type: SET_INITIALIZING,
					payload: false
				});
			}
		}
	};

	// Set Search Text
	const setSearchText = async (value) => {
		dispatch({
			type: SET_SEARCH_TEXT,
			payload: value
		});
	};

	// Get Document by Solution Id
	const getDocumentBySolutionId = async (id) => {
		axios.get(apiHost + '/document/get/solutionId/' + id, headerConfig()).then((res) => {
			dispatch({
				type: SET_DOCUMENT,
				payload: res.data.data
			});
			dispatch({
				type: SET_INITIALIZING,
				payload: false
			});
		});
	};

	// // Set Initializing
	// const setInitializing = async (value) => {
	// 	dispatch({
	// 		type: SET_INITIALIZING,
	// 		payload: value
	// 	});
	// };

	// // Set Loading
	// const setLoading = async (value) => {
	// 	dispatch({
	// 		type: SET_LOADING,
	// 		payload: value
	// 	});
	// };

	// // Set Processing Success
	// const setProcessingSuccess = async (value) => {
	// 	dispatch({
	// 		type: SET_PROCESSING_SUCCESS,
	// 		payload: value
	// 	});
	// };

	// // Set Processing Error
	// const setProcessingError = async (value) => {
	// 	dispatch({
	// 		type: SET_PROCESSING_ERROR,
	// 		payload: value
	// 	});
	// };

	// // Set Visible Component
	// const setVisibleComponent = async (value) => {
	// 	dispatch({
	// 		type: SET_VISIBLE_COMPONENT,
	// 		payload: value
	// 	});
	// };

	// // Set Page Title
	// const setPageTitle = async (value) => {
	// 	dispatch({
	// 		type: SET_PAGE_TITLE,
	// 		payload: value
	// 	});
	// };

	// // Set Selected Id
	// const setSelectedId = async (id) => {
	// 	dispatch({
	// 		type: SET_SELECTED_ID,
	// 		payload: id
	// 	});
	// };

	// // Clear Document
	// const clearDocument = async () => {
	// 	dispatch({
	// 		type: SET_DOCUMENT,
	// 		payload: null
	// 	});
	// };

	// // Validate File
	// const validateFile = async (file) => {
	// 	const isValid = await validateFileExtension(file);
	// 	if (!isValid) {
	// 		dispatch({
	// 			type: SET_PROCESSING_ERROR,
	// 			payload: 'Invalid file type'
	// 		});
	// 		dispatch({
	// 			type: SET_ENABLE_BUTTON,
	// 			payload: false
	// 		});
	// 	} else {
	// 		dispatch({
	// 			type: SET_ENABLE_BUTTON,
	// 			payload: true
	// 		});
	// 	}
	// };

	// 	dispatch({
	// 		type: SET_DOCUMENT,
	// 		payload: null
	// 	});
	// 	dispatch({
	// 		type: SET_INTERNAL_SOLUTION_ID,
	// 		payload: internalSolutionId
	// 	});
	// 	dispatch({
	// 		type: SET_SOLUTION_ID,
	// 		payload: solutionId
	// 	});
	// 	dispatch({
	// 		type: SET_DOCUMENT_CONTENT,
	// 		payload: content
	// 	});
	// 	dispatch({
	// 		type: SET_LOADING,
	// 		payload: false
	// 	});
	// 	dispatch({
	// 		type: SET_INITIALIZING,
	// 		payload: false
	// 	});
	// };

	// // Download Document
	// const downloadDocument = async (documentId, fileName) => {
	// 	dispatch({
	// 		type: SET_IS_DOWNLOADING,
	// 		payload: true
	// 	});
	// 	fetch(apiHost + '/document/' + documentId + '/download/', {
	// 		method: 'GET',
	// 		headers: {
	// 			'Content-Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	// 			'csrf-token': Cookies.get('XSRF-TOKEN'),
	// 			Authorization: 'Bearer ' + localStorage.getItem('_mhcms')
	// 		}
	// 	})
	// 		.then((response) => response.blob())
	// 		.then((blob) => {
	// 			// Create blob link to download
	// 			const url = window.URL.createObjectURL(new Blob([ blob ]));
	// 			const link = document.createElement('a');
	// 			link.href = url;
	// 			link.setAttribute('download', fileName + `.docx`);

	// 			// Append to html link element page
	// 			document.body.appendChild(link);

	// 			// Start download
	// 			link.click();

	// 			// Clean up and remove the link
	// 			link.parentNode.removeChild(link);

	// 			dispatch({
	// 				type: SET_IS_DOWNLOADING,
	// 				payload: false
	// 			});
	// 		});
	// };

	return (
		<DocumentContext.Provider
			value={{
				documentProcessing: state.documentProcessing,
				isSearching: state.isSearching,
				searchQuery: state.searchQuery,
				totalDocumentCount: state.totalDocumentCount,
				enableSubmitBtn: state.enableSubmitBtn,
				validFile: state.validFile,
				document: state.document,
				searchText: state.searchText,
				searchResults: state.searchResults,
				documentContent: state.documentContent,
				isInitializing: state.isInitializing,
				solutionId: state.solutionId,
				internalSolutionId: state.internalSolutionId,
				isDownloading: state.isDownloading,
				getTotalDocuments,
				setSearchQuery,
				setSearching,
				setIsProcessing,
				uploadDocument,
				setEnableSubmitButton,
				validateFileExtension,
				getDocumentById,
				clearDocument,
				downloadDocument,
				updateDocument,
				searchDocuments,
				clearSolutionId,
				clearSearchResults,
				setDocumentContent,
				setSearchText,
				getDocumentBySolutionId
			}}
		>
			{props.children}
		</DocumentContext.Provider>
	);
};

export default DocumentState;
