import { makeStyles } from '@material-ui/core/styles';

const createStyle = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(1),
		marginTop: 20,
		marginLeft: -5,
		width: '100%'
	},
	rootTitle: {
		padding: 15,
		marginTop: 0,
		marginLeft: -5,
		width: '100%'
	},
	margin: {
		margin: theme.spacing(1)
	},
	extendedIcon: {
		marginRight: theme.spacing(1)
	},
	formControl: {
		marginBottom: 10,
		minWidth: 300,
		[theme.breakpoints.up('sm')]: {
			minWidth: 500
		},
		marginRight: theme.spacing(2),
		marginBottom: theme.spacing(2)
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	textFieldMedium: {
		minWidth: 300,
		[theme.breakpoints.up('sm')]: {
			minWidth: 500
		},
		marginRight: theme.spacing(2),
		marginBottom: theme.spacing(2)
	},
	textFieldDate: {
		marginRight: 10
	},
	divider: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		marginLeft: 0,
		marginRight: 0
	},
	btnIcon: {
		marginLeft: 10
	},
	txtError: {
		color: 'red'
	},
	snackbarWidth: {
		minWidth: 200,
		maxWidth: 200
	}
}));

export default createStyle;
