import {
	SET_IS_AUTHENTICATED,
	SET_LOADING,
	AUTH_SUCCESS,
	AUTH_ERROR,
	SET_LOGIN_EMAIL,
	SET_AUTHORIZATION,
	SET_AUTH_MEMBER,
	SET_IS_VERIFYING_AUTHENTICATION,
	SET_IS_PROCESSING,
	CLEAR_ERRORS,
	IS_SETTING_ALERT
} from './authTypes';

export default (state, action) => {
	switch (action.type) {
		case SET_IS_PROCESSING:
			return {
				...state,
				isProcessing: action.payload
			};
		case SET_LOADING:
			return {
				...state,
				isAuthenticationLoading: action.payload
			};
		case SET_IS_AUTHENTICATED:
			return {
				...state,
				isAuthenticated: action.payload
			};
		case SET_IS_VERIFYING_AUTHENTICATION:
			return {
				...state,
				isVerifyingAuthentication: action.payload
			};
		case AUTH_SUCCESS:
			return {
				...state,
				isAuthenticated: true
			};
		case AUTH_ERROR:
			return {
				...state,
				isAuthenticated: false,
				error: true
			};
		case SET_LOGIN_EMAIL:
			return {
				...state,
				loginEmail: action.payload
			};
		case SET_AUTHORIZATION:
			return {
				...state,
				isAuthorized: true
			};
		case SET_AUTH_MEMBER:
			return {
				...state,
				authMember: action.payload
			};
		case CLEAR_ERRORS:
			return {
				...state,
				error: false
			};
		case IS_SETTING_ALERT:
			return {
				...state,
				showAlert: action.payload
			};
		default:
			return state;
	}
};
