import React, { useReducer } from 'react';
import axios from 'axios';
import AuthContext from './authContext';
import authReducer from './authReducer';
import Cookies from 'js-cookie';
import {
	SET_IS_PROCESSING,
	SET_IS_AUTHENTICATED,
	AUTH_SUCCESS,
	AUTH_ERROR,
	SET_LOGIN_EMAIL,
	SET_AUTHORIZATION,
	SET_AUTH_MEMBER,
	SET_LOADING,
	CLEAR_ERRORS,
	IS_SETTING_ALERT
} from './authTypes';
import moment from 'moment';

// Setup config/headers and token
const headerConfig = () => {
	// Headers
	const config = {
		headers: {
			'Content-type': 'application/json',
			'csrf-token': Cookies.get('XSRF-TOKEN'),
			Authorization: 'Bearer ' + localStorage.getItem('_mhcms')
		},
		withCredentials: true
	};

	return config;
};

const AuthState = (props) => {
	const initialState = {
		isProcessing: null,
		isAuthenticationLoading: null,
		isAuthenticated: null,
		isAuthorized: null,
		authMember: {},
		loginEmail: null,
		error: false,
		showAlert: false
	};

	const [ state, dispatch ] = useReducer(authReducer, initialState);

	//	const apiHost = 'http://localhost:5000';
	const apiHost = 'https://prod.api.microscopehc.solvaris.net';

	// Set Processing
	const setProcessing = async (value) => {
		dispatch({
			type: SET_IS_PROCESSING,
			payload: value
		});
	};

	// Set Loading
	const setAuthenticationLoading = async (value) => {
		dispatch({
			type: SET_LOADING,
			payload: value
		});
	};

	// Check if user is authenticated
	const setIsAuthenticated = async () => {
		const token = localStorage.getItem('_mhcms');
		if (token !== null) {
			dispatch({
				type: SET_IS_AUTHENTICATED,
				payload: true
			});
		} else {
			dispatch({
				type: SET_IS_AUTHENTICATED,
				payload: false
			});
		}
	};

	// Authorization Check
	const isAuthorizedCheck = async (requiredAuthLevel) => {
		setProcessing(true);
		axios
			.get(apiHost + '/auth/verify', headerConfig())
			.then((res) => {
				if (!res.data.success) {
					logoutMember();
				} else {
					switch (requiredAuthLevel) {
						case 'Admin Access':
							if (res.data.data == 'Admin') {
								setAuthMember(res.data.data);
								dispatch({
									type: SET_AUTHORIZATION,
									payload: true
								});
								dispatch({
									type: SET_IS_PROCESSING,
									payload: false
								});
								break;
							} else {
								setAuthMember(null);
								dispatch({
									type: SET_AUTHORIZATION,
									payload: false
								});
								dispatch({
									type: SET_IS_PROCESSING,
									payload: false
								});
								break;
							}
						case 'User Access':
							if (res.data.data == 'User') {
								setAuthMember(res.data.data);
								dispatch({
									type: SET_AUTHORIZATION,
									payload: true
								});
								dispatch({
									type: SET_IS_PROCESSING,
									payload: false
								});
								break;
							} else {
								setAuthMember(null);
								dispatch({
									type: SET_AUTHORIZATION,
									payload: false
								});
								dispatch({
									type: SET_IS_PROCESSING,
									payload: false
								});
								break;
							}
						case 'All':
							if (res.data.data == 'Admin' || res.data.data == 'User') {
								setAuthMember(res.data.data);
								dispatch({
									type: SET_AUTHORIZATION,
									payload: true
								});
								dispatch({
									type: SET_IS_PROCESSING,
									payload: false
								});
								break;
							} else {
								setAuthMember(null);
								dispatch({
									type: SET_AUTHORIZATION,
									payload: false
								});
								dispatch({
									type: SET_IS_PROCESSING,
									payload: false
								});
								break;
							}
						default:
							setAuthMember(null);
							dispatch({
								type: SET_AUTHORIZATION,
								payload: false
							});
							dispatch({
								type: SET_IS_PROCESSING,
								payload: false
							});
							break;
					}
				}
			})
			.catch((err) => {
				logoutMember();
			});
	};

	// Set isAuthorized state
	const setIsAuthorized = async (isAuthorized) => {
		dispatch({
			type: SET_AUTHORIZATION,
			payload: isAuthorized
		});
	};

	// Set Auth Member
	const setAuthMember = async (role) => {
		const authMemberObj = {
			role: role
		};
		dispatch({
			type: SET_AUTH_MEMBER,
			payload: authMemberObj
		});
	};

	// Logout Member
	const logoutMember = async () => {
		localStorage.removeItem('_mhcms');
		dispatch({
			type: AUTH_ERROR,
			payload: false
		});
	};

	// Set Login Form Email
	const setLoginEmail = async (email) => {
		dispatch({
			type: SET_LOGIN_EMAIL,
			payload: email
		});
	};

	// Login Member
	const loginMember = async (email, password) => {
		dispatch({
			type: SET_IS_PROCESSING,
			payload: true
		});
		dispatch({
			type: SET_LOADING,
			payload: true
		});
		const body = JSON.stringify({ email, password });
		axios
			.post(apiHost + '/auth/login', body, headerConfig(state.token))
			.then((res) => {
				if (res.data.success) {
					localStorage.setItem('_mhcms', res.data.data.token);
					dispatch({
						type: IS_SETTING_ALERT,
						payload: false
					});
					dispatch({
						type: AUTH_SUCCESS,
						payload: res.data.data
					});
					dispatch({
						type: SET_IS_PROCESSING,
						payload: false
					});
					dispatch({
						type: SET_LOADING,
						payload: false
					});
				} else {
					dispatch({
						type: AUTH_ERROR
					});
					dispatch({
						type: IS_SETTING_ALERT,
						payload: true
					});
					dispatch({
						type: SET_IS_PROCESSING,
						payload: false
					});
					dispatch({
						type: SET_LOADING,
						payload: false
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: AUTH_ERROR
				});
				dispatch({
					type: IS_SETTING_ALERT,
					payload: true
				});
				dispatch({
					type: SET_IS_PROCESSING,
					payload: false
				});
				dispatch({
					type: SET_LOADING,
					payload: false
				});
			});
	};

	// Clear Errors
	const clearErrors = async () => {
		dispatch({
			type: CLEAR_ERRORS
		});
	};

	const settingAlert = async (value) => {
		dispatch({
			type: IS_SETTING_ALERT,
			payload: value
		});
	};

	return (
		<AuthContext.Provider
			value={{
				isProcessing: state.isProcessing,
				isAuthenticationLoading: state.isAuthenticationLoading,
				isAuthenticated: state.isAuthenticated,
				isAuthorized: state.isAuthorized,
				authMember: state.authMember,
				error: state.error,
				showAlert: state.showAlert,
				setProcessing,
				setAuthenticationLoading,
				setIsAuthenticated,
				isAuthorizedCheck,
				loginMember,
				logoutMember,
				setLoginEmail,
				clearErrors,
				settingAlert
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

export default AuthState;
