import {
	SET_LOADING,
	SET_IS_PROCESSING,
	SET_PROCESSING_SUCCESS,
	SET_PROCESSING_ERROR,
	SET_VISIBLE_COMPONENT,
	SET_PAGE_TITLE,
	SET_SELECTED_ID,
	SET_INITIALIZING,
	SET_TOTAL_RECORD_COUNT,
	SET_DOCUMENT,
	SET_ENABLE_BUTTON,
	SET_SEARCH_RESULTS,
	SET_RESEARCH_DOCUMENTS,
	SET_SOLUTION_ID,
	SET_IS_SEARCHING,
	SET_INTERNAL_SOLUTION_ID,
	SET_DOCUMENT_CONTENT,
	SET_SEARCH_TEXT,
	SET_IS_DOWNLOADING,
	SET_SEARCH_QUERY,
	SET_VALID_FILE
} from './documentType';

export default (state, action) => {
	switch (action.type) {
		case SET_RESEARCH_DOCUMENTS:
			return {
				...state,
				researchDocuments: action.payload
			};
		case SET_VALID_FILE:
			return {
				...state,
				validFile: action.payload
			};
		case SET_SOLUTION_ID:
			return {
				...state,
				solutionId: action.payload
			};
		case SET_SEARCH_QUERY:
			return {
				...state,
				searchQuery: action.payload
			};
		case SET_IS_DOWNLOADING:
			return {
				...state,
				isDownloading: action.payload
			};
		case SET_INTERNAL_SOLUTION_ID:
			return {
				...state,
				internalSolutionId: action.payload
			};
		case SET_INITIALIZING:
			return {
				...state,
				isInitializing: action.payload
			};
		case SET_LOADING:
			return {
				...state,
				isLoading: action.payload
			};
		case SET_IS_PROCESSING:
			return {
				...state,
				documentProcessing: action.payload
			};
		case SET_IS_SEARCHING:
			return {
				...state,
				isSearching: action.payload
			};
		case SET_PROCESSING_SUCCESS:
			return {
				...state,
				processingSuccess: action.payload
			};
		case SET_PROCESSING_ERROR:
			return {
				...state,
				processingError: action.payload
			};
		case SET_ENABLE_BUTTON:
			return {
				...state,
				enableSubmitBtn: action.payload
			};
		case SET_VISIBLE_COMPONENT:
			return {
				...state,
				visibleComponent: action.payload
			};
		case SET_DOCUMENT:
			return {
				...state,
				document: action.payload
			};
		case SET_PAGE_TITLE:
			return {
				...state,
				pageTitle: action.payload
			};
		case SET_SELECTED_ID:
			return {
				...state,
				selectedId: action.payload
			};
		case SET_TOTAL_RECORD_COUNT:
			return {
				...state,
				totalDocumentCount: action.payload
			};
		case SET_SEARCH_RESULTS:
			return {
				...state,
				searchResults: action.payload
			};
		case SET_DOCUMENT_CONTENT:
			return {
				...state,
				documentContent: action.payload
			};
		case SET_SEARCH_TEXT: {
			return {
				...state,
				searchText: action.payload
			};
		}
		default:
			return state;
	}
};
