import { makeStyles } from '@material-ui/core/styles';

const loginStyle = makeStyles((theme) => ({
	logo: {
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		},
		maxWidth: 200
	},
	paper: {
		marginTop: 100,
		padding: 15,
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		alignItems: 'center !important',
		textAlign: 'center !important',
		justifyContent: 'center !important',
		boxSizing: 'inherit',
		width: '90%',
		[theme.breakpoints.up('sm')]: {
			width: 500
		}
	},
	loginIcon: {
		display: 'flex !important',
		justifyContent: 'center !important'
	},
	margin: {
		marginTop: 5,
		marginBottom: 0
	},
	recaptchAppbar: {
		height: 20,
		backgroundColor: '#e0e0e0',
		color: '#292929',
		width: '100%',
		position: 'fixed',
		bottom: 0,
		paddingTop: 5,
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex'
	}
}));

export default loginStyle;
