import React, { useState, useContext, useEffect } from 'react';
import AuthContext from '../../context/authContext/authContext';
import AlertContext from '../../context/alertContext/alertContext';
import Alert from '../alert/AlertDisplay';
import AppBarMain from '../../components/appBar/AppBar';
import isEmail from 'validator/lib/isEmail';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { load } from 'recaptcha-v3';
import MicroscopHCLogo from '../../images/microscopeHC_Logo.png';
import { useHistory } from 'react-router-dom';
import LoginStyle from './LoginStyle';
import Paper from '@material-ui/core/Paper';
import Loading from '../common/Loading';

const Login = (props) => {
	const classes = LoginStyle();
	const authContext = useContext(AuthContext);
	const alertContext = useContext(AlertContext);
	const {
		isProcessing,
		isAuthenticated,
		setAuthenticationLoading,
		isAuthenticationLoading,
		setIsAuthenticated,
		setLoginEmail,
		loginEmail,
		loginMember,
		error,
		clearErrors,
		showAlert,
		settingAlert
	} = authContext;
	const { setAlert, clearAlert } = alertContext;

	const history = useHistory();

	const [ member, setMember ] = useState({
		memberemail: loginEmail !== null ? loginEmail : '',
		password: ''
	});

	const { memberemail, password } = member;

	useEffect(
		() => {
			if (isAuthenticated === null) {
				setAuthenticationLoading(true);
				setIsAuthenticated();
			} else if (isAuthenticated) {
				setAuthenticationLoading(false);
				props.history.push('/');
			} else if (!isAuthenticated && !isProcessing) {
				setAuthenticationLoading(false);
			}

			if (error & showAlert) {
				settingAlert(false);
				setAlert('Sign in failed.', 'Please enter a valid email and password.', 'error', 7000);
			}
		},
		[ isAuthenticated, isAuthenticationLoading, loginEmail, error, showAlert ]
	);

	const onChange = (e) => {
		setMember({ ...member, [e.target.name]: e.target.value });
		if (e.target.name === 'memberemail') {
			setLoginEmail(e.target.value);
		}
	};

	async function onSubmit(e) {
		e.preventDefault();
		//	clearAlert();
		let validEmail = isEmail(memberemail);
		if (validEmail && password.length > 6) {
			loginMember(memberemail, password);
		} else {
			settingAlert(true);
			setAlert('Oops! Sign in failed.', 'Please enter a valid email and password.', 'error', 7000);
		}
	}

	const redirect = (route) => {
		history.push('/' + route);
	};

	if (isAuthenticationLoading) {
		return <Loading />;
	} else if (!isAuthenticationLoading) {
		return (
			<Grid>
				<AppBarMain />
				<Grid container direction="row" justify="center" alignItems="center">
					<Paper className={classes.paper} elevation={3}>
						<div className="loginIcon">
							<img src={MicroscopHCLogo} className={classes.logo} alt="Logo" />
						</div>
						<form className="form" onSubmit={onSubmit}>
							<TextField
								variant="outlined"
								margin="normal"
								required={true}
								fullWidth
								id="memberemail"
								label="Email Address"
								name="memberemail"
								autoComplete="memberemail"
								autoFocus
								onChange={onChange}
								value={member.email}
							/>
							<TextField
								variant="outlined"
								margin="normal"
								required={true}
								fullWidth
								name="password"
								label="Password"
								type="password"
								id="password"
								autoComplete="current-password"
								onChange={onChange}
							/>
							<Alert />
							<Button type="submit" fullWidth variant="contained" color="primary" className="submit">
								Sign In
							</Button>
							{/*
							
							<Grid container spacing={2} className={classes.margin}>
								<Grid item xs={6}>
									<Link href="#" onClick={() => redirect('firstlogin')} variant="body2">
										First time signing in?
									</Link>
								</Grid>
								<Grid item xs={6}>
									<Link href="#" onClick={() => redirect('forgotpassword')} variant="body2">
										Forgot password?
									</Link>
								</Grid>
							</Grid>
							*/}
						</form>
					</Paper>
				</Grid>
				<Grid className={classes.recaptchAppbar}>
					<Typography variant="caption" display="block" gutterBottom>
						This site is protected by reCAPTCHA and the Google{' '}
						<Link target="_blank" href="https://policies.google.com/privacy" variant="caption">
							Privacy Policy
						</Link>{' '}
						and{' '}
						<Link target="_blank" href="https://policies.google.com/terms" variant="caption">
							Terms of Service
						</Link>{' '}
						apply.
					</Typography>
				</Grid>
			</Grid>
		);
	} else {
		return <Loading />;
	}
};

export default Login;
