import React, { useContext, useEffect } from 'react';
import AuthContext from '../../context/authContext/authContext';
import AppBar from '@material-ui/core/AppBar';
import AppBarStyle from './AppBarStyle';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import DrawerComponent from './DrawerComponent';
import MicroscopeHCLogo from '../../images/microscopeHC_Logo.png';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import { useHistory } from 'react-router-dom';
import AlertDisplay from '../alert/AlertDisplay';
import UserProfileAppBar from '../auth/UserProfileAppBar';

function AppBarComponent(props) {
	const classes = AppBarStyle();
	const authContext = useContext(AuthContext);
	const { isAuthenticated } = authContext;
	const { container } = props;
	const theme = useTheme();
	const [ mobileOpen, setMobileOpen ] = React.useState(false);
	const history = useHistory();

	useEffect(() => {}, [ isAuthenticated ]);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const redirect = () => {
		history.push('/');
	};

	if (isAuthenticated) {
		return (
			<React.Fragment>
				<AppBar position="fixed" className={classes.appBar}>
					<Toolbar>
						<Hidden smUp implementation="css">
							<IconButton
								color="inherit"
								aria-label="open drawer"
								edge="start"
								onClick={handleDrawerToggle}
								className={classes.menuButton}
							>
								<MenuIcon />
							</IconButton>
						</Hidden>
						<Typography variant="h6" noWrap className={classes.title}>
							Margin Solutions Express
						</Typography>
						<UserProfileAppBar />
					</Toolbar>
				</AppBar>
				<nav className={classes.drawer} aria-label="links">
					{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
					<Hidden smUp implementation="css">
						<Drawer
							container={container}
							variant="temporary"
							anchor={theme.direction === 'rtl' ? 'right' : 'left'}
							open={mobileOpen}
							onClose={handleDrawerToggle}
							classes={{
								paper: classes.drawerPaper
							}}
							ModalProps={{
								keepMounted: true // Better open performance on mobile.
							}}
						>
							{<DrawerComponent />}
						</Drawer>
					</Hidden>
					<Hidden xsDown implementation="css">
						<Drawer
							classes={{
								paper: classes.drawerPaper
							}}
							variant="permanent"
							open
						>
							{<DrawerComponent />}
						</Drawer>
					</Hidden>
				</nav>
			</React.Fragment>
		);
	} else {
		return null;
	}
}

export default AppBarComponent;
