import React, { useEffect, useContext, useState } from 'react';
import AuthContext from '../../context/authContext/authContext';
import DocumentContext from '../../context/documentContext/documentContext';
import MaterialTable, { MTableToolbar, MTableCell } from 'material-table';
import DocumentStyle from '../../components/document/DocumentStyle';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Loading from '../../components/common/Loading';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { useHistory } from 'react-router-dom';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { BlobLeaseClient, BlobServiceClient } from '@azure/storage-blob';
import '../common/MaterialIconsStyleSheet.css';
import Searching from '../common/Searching';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const SearchResults = () => {
	const classes = DocumentStyle();
	const authContext = useContext(AuthContext);
	const documentContext = useContext(DocumentContext);
	const { user } = authContext;
	const {
		isDownloading,
		searchResults,
		setResearchDocuments,
		downloadDocument,
		isSearching,
		setSearching,
		setDocumentContent
	} = documentContext;
	const history = useHistory();

	const account = 'microscopehc';
	const sas =
		'?sv=2020-02-10&ss=b&srt=sco&sp=rwdlacx&se=2021-04-10T04:53:52Z&st=2021-04-09T20:53:52Z&spr=https&sig=aJODrPZRzDulyFs%2BTdI1Ee%2FUPeK89zm7clO0I6ieVUM%3D';
	const containerName = 'solutiondocuments';

	const [ state, setState ] = React.useState({
		columns: [
			{
				title: 'Id',
				field: 'solutionId',
				hidden: true,
				width: '0'
			},
			{
				title: 'Solution ID',
				field: 'internalSolutionId',
				editable: 'never',
				width: '10%',
				render: (rowData) => (
					<Link className={classes.textLink} target="_blank" href={formatSolutionURL(rowData.solutionId)}>
						{rowData.internalSolutionId}
					</Link>
				)
			},
			{
				title: 'Solution Name',
				field: 'fileName',
				editable: 'never',
				width: '20%'
			},
			{
				title: '',
				field: '',
				editable: 'never',
				width: '10%',
				render: (rowData) => (
					<Typography
						className={classes.tableLink}
						variant="body2"
						gutterBottom
						onClick={() => viewFileContent(rowData.solutionId, rowData.internalSolutionId, rowData.content)}
					>
						View File Content
					</Typography>
				)
			},
			{
				title: '',
				field: '',
				editable: 'never',
				width: '10%',
				render: (rowData) => (
					<Typography
						//	className={classes.tableLink}
						variant="body2"
						gutterBottom
						//	onClick={() => viewResearchDocuments(rowData.internalSolutionId)}
					>
						Research Documents
					</Typography>
				)
			}
		],
		data: [
			{
				SolutionID: '',
				InternalSolutionID: '',
				Name: '',
				Description: ''
			}
		]
	});

	useEffect(
		() => {
			if (searchResults !== null) {
				setSearching(false);
				setState({ ...state, data: searchResults });
			}
		},
		[ searchResults, isDownloading ]
	);

	const viewResearchDocuments = (solutionId) => {
		setResearchDocuments(solutionId);
	};

	const viewFileContent = (solutionId, internalSolutionId, content) => {
		setDocumentContent(solutionId, internalSolutionId, content);
		history.push('/document/content/');
	};

	const formatSolutionURL = (solutionId) => {
		return (
			'https://app.thesevenexgroup.com/AppAdmin/AssessmentsSolutions/Solutions/EditSolution.aspx?ID=' + solutionId
		);
	};

	const [ selectedRow, setSelectedRow ] = useState(null);

	if (isSearching) {
		return <Searching />;
	} else if (searchResults != null && !isSearching) {
		return (
			<React.Fragment>
				<Grid className={classes.table}>
					<MaterialTable
						columns={state.columns}
						options={{
							showTitle: false,
							search: false,
							paging: true,
							pageSize: 5,
							actionsColumnIndex: -1,
							rowStyle: (rowData) => ({
								backgroundColor: selectedRow === rowData.tableData.id ? '#EEE' : '#FFF'
							})
						}}
						data={state.data}
						// data={(query) =>
						// 	new Promise((resolve, reject) => {
						// 		console.log(query);
						// 		console.log(state.data);
						// 		resolve({
						// 			data: state.data,
						// 			page: query.page,
						// 			totalCount: state.data.length
						// 		});
						// 	})}
						components={{
							Toolbar: (props) => (
								<div style={{ display: 'none' }}>
									<MTableToolbar {...props} />
								</div>
							)
						}}
					/>
				</Grid>
			</React.Fragment>
		);
	} else {
		return null;
	}
};
export default SearchResults;
