import React, { useReducer } from 'react';
import AlertContext from './alertContext';
import alertReducer from './alertReducer';
import { SET_ALERT, CLEAR_ALERT } from './alertTypes';

const AlertState = (props) => {
	const initialState = {
		alert: {
			title: '',
			message: '',
			type: '',
			duration: ''
		},
		isSettingAlert: false
	};

	const [ state, dispatch ] = useReducer(alertReducer, initialState);

	// Set Alert
	const setAlert = async (title, message, type, duration) => {
		let obj = {
			title: title,
			message: message,
			type: type,
			duration: duration
		};
		dispatch({
			type: SET_ALERT,
			payload: obj
		});
	};

	// Clear Alert
	const clearAlert = async () => {
		let obj = {
			title: '',
			message: '',
			type: '',
			duration: ''
		};
		dispatch({
			type: CLEAR_ALERT,
			payload: obj
		});
	};

	return (
		<AlertContext.Provider
			value={{
				alert: state.alert,
				setAlert,
				clearAlert
			}}
		>
			{props.children}
		</AlertContext.Provider>
	);
};

export default AlertState;
