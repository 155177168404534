import React, { useState, useContext, useEffect } from 'react';
import AlertContext from '../../context/alertContext/alertContext';
import DocumentContext from '../../context/documentContext/documentContext';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import SolutionSearchStyle from './SolutionSearchStyle';
import Grid from '@material-ui/core/Grid';

const SearchInput = () => {
	const classes = SolutionSearchStyle();
	const alertContext = useContext(AlertContext);
	const documentContext = useContext(DocumentContext);
	const { setAlert } = alertContext;
	const {
		searchText,
		searchDocuments,
		clearSolutionId,
		clearSearchResults,
		setSearching,
		setDocumentContent,
		setSearchText
	} = documentContext;

	const [ param, setParam ] = useState({
		search: searchText != null ? searchText : ''
	});
	const { search, processingError } = param;

	useEffect(
		() => {
			if (processingError) {
				setAlert('Search Error', 'An error occured. Please try again.', 'error', 5000);
			}
		},
		[ processingError, searchText ]
	);

	const onChange = (e) => setParam({ ...param, [e.target.name]: e.target.value });

	const onSubmit = (e) => {
		e.preventDefault();
		const param = {
			search
		};
		if (param.search.length > 0) {
			setSearching(true);
			setSearchText(param.search);
			clearSearchResults();
			clearSolutionId();
			setDocumentContent(null, null);
			searchDocuments(param);
		}
	};

	return (
		<React.Fragment>
			<form className={classes.root} onSubmit={onSubmit}>
				<Grid item>
					<TextField
						label="Search Solution Documents"
						className={classes.textField}
						onChange={onChange}
						value={param.search}
						margin="normal"
						variant="outlined"
						id="search"
						name="search"
					/>
				</Grid>
				<Grid item>
					<Fab type="submit" color="primary" aria-label="search" className={classes.fab}>
						<SearchIcon />
					</Fab>
				</Grid>
			</form>
		</React.Fragment>
	);
};
export default SearchInput;
