import React, { useContext, useEffect } from 'react';
import AuthContext from '../../context/authContext/authContext';
import { Redirect } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Loading from '../common/Loading';

const Logout = (props) => {
	const authContext = useContext(AuthContext);
	const { logoutMember, isAuthenticated } = authContext;

	useEffect(
		() => {
			logoutMember();
		},
		[ logoutMember, isAuthenticated, props.history ]
	);

	if (isAuthenticated) {
		return (
			<React.Fragment>
				<Loading />
			</React.Fragment>
		);
	}

	return <Redirect to="/login" />;
};

export default Logout;
