import React, { useContext } from 'react';
import DocumentContext from '../../context/documentContext/documentContext';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import DocumentStyle from './DocumentStyle';
import { useHistory } from 'react-router-dom';

const PageTitle = () => {
	const classes = DocumentStyle();
	const history = useHistory();
	const documentContext = useContext(DocumentContext);
	const { setIsProcessing, clearDocument } = documentContext;

	const redirect = () => {
		setIsProcessing(null);
		clearDocument();
		history.push('/document/add');
	};

	return (
		<React.Fragment>
			<Grid container spacing={1}>
				<Paper className={classes.root} elevation={3}>
					<Grid container>
						<Grid item xs={9}>
							<Typography variant="h5" gutterBottom>
								Documents
							</Typography>
						</Grid>
						<Grid item xs={3} container justify="flex-end">
							<Button
								variant="contained"
								size="small"
								color="primary"
								aria-label="add"
								className={classes.btnAdd}
								onClick={() => redirect()}
							>
								<Typography className={classes.btnNewText} variant="button" gutterBottom>
									Add Document
								</Typography>
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</React.Fragment>
	);
};
export default PageTitle;
