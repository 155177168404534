import { makeStyles } from '@material-ui/core/styles';

const loadingStyle = makeStyles((theme) => ({
	logo: {
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		},
		maxWidth: 200
	},
	paper: {
		marginTop: 100,
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		alignItems: 'center !important',
		textAlign: 'center !important',
		justifyContent: 'center !important',
		boxSizing: 'inherit'
	}
}));

export default loadingStyle;
