import React, { useEffect, useState, useContext } from 'react';
import AuthContext from '../../context/authContext/authContext';
import DocumentContext from '../../context/documentContext/documentContext';
import Grid from '@material-ui/core/Grid';
import DocumentStyle from './DocumentStyle';
import { Typography } from '@material-ui/core';
import AppBar from '../appBar/AppBar';
import GlobalStyle from '../common/GlobalStyle';
import Loading from '../common/Loading';
import { Redirect, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const DocumentContentComponent = () => {
	const globalStyle = GlobalStyle();
	const classes = DocumentStyle();
	const authContext = useContext(AuthContext);
	const documentContext = useContext(DocumentContext);
	const { isAuthenticated, isAuthorizedCheck, isAuthorized } = authContext;
	const {
		documentContent,
		isInitializing,
		solutionId,
		internalSolutionId,
		downloadDocument,
		isDownloading,
		getDocumentBySolutionId,
		document
	} = documentContext;
	const history = useHistory();

	useEffect(
		() => {
			if (document == null) {
				getDocumentBySolutionId(internalSolutionId);
			}
		},
		[ isAuthenticated, isAuthorized, documentContent, isDownloading, document ]
	);

	const back = () => {
		history.push('/');
	};

	const getSolutionLink = () => {
		return (
			'https://app.thesevenexgroup.com/AppAdmin/AssessmentsSolutions/Solutions/EditSolution.aspx?ID=' + solutionId
		);
	};

	const download = async () => {
		downloadDocument(document.id, document.fileName);
	};

	if (document == null) {
		return <Loading />;
	} else if (isAuthenticated && isAuthorized && !isInitializing) {
		return (
			<React.Fragment>
				<AppBar />
				<div className={globalStyle.root}>
					<Grid container spacing={1} className={classes.contentBody}>
						<Grid item xs={12}>
							<Button
								variant="contained"
								size="small"
								color="primary"
								aria-label="add"
								className={classes.btnAdd}
								onClick={() => back()}
							>
								<Typography className={classes.btnNewText} variant="button" gutterBottom>
									Back to Search Results
								</Typography>
							</Button>
							<Button
								variant="contained"
								size="small"
								color="secondary"
								aria-label="add"
								className={classes.btnAdd}
								target="_blank"
								href={getSolutionLink()}
							>
								<Typography className={classes.btnNewText} variant="button" gutterBottom>
									View Solution
								</Typography>
							</Button>

							{isDownloading ? (
								<CircularProgress color="secondary" />
							) : (
								<Button
									variant="contained"
									size="medium"
									color="secondary"
									aria-label="cancel"
									className={classes.margin}
									onClick={() => download()}
								>
									Download Document
								</Button>
							)}
						</Grid>
						<Grid className={classes.documentContentMargin} item xs={12}>
							<Typography component="div" variant="body1" style={{ whiteSpace: 'pre-line' }}>
								{documentContent}
							</Typography>
						</Grid>
					</Grid>
				</div>
			</React.Fragment>
		);
	} else if (!isAuthenticated) {
		return <Redirect to="/logout" />;
	} else if (isInitializing) {
		return <Loading />;
	} else if (isAuthorized === null) {
		return <Loading />;
	} else if (isAuthenticated && !isAuthorized) {
		return <Redirect to="/NotAuthorized" />;
	} else {
		return <Redirect to="/logout" />;
	}
};
export default DocumentContentComponent;
