import React, { useContext } from 'react';
import AuthContext from '../../context/authContext/authContext';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircle from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

const StyledMenu = withStyles({
	paper: {
		border: '1px solid #3E444D'
	}
})((props) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center'
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center'
		}}
		{...props}
	/>
));

const StyledMenuItem = withStyles((theme) => ({
	root: {
		'&:focus': {
			backgroundColor: theme.palette.primary.main,
			'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
				color: theme.palette.common.white
			}
		}
	}
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
	link: {
		'&:hover': {
			color: 'inherit'
		}
	},
	accountIcon: {
		color: '#eeeeee',
		backgroundColor: '#666666',
		'&:hover, &.Mui-focusVisible': { color: '#fafafa', backgroundColor: '#666666' }
	},
	menuText: {
		margin: theme.spacing(2)
	}
}));

function UserProfileAppBar() {
	const classes = useStyles();
	const [ anchorEl, setAnchorEl ] = React.useState(null);
	const authContext = useContext(AuthContext);
	const { user } = authContext;

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			<IconButton className={classes.accountIcon} onClick={handleClick} color="default">
				<AccountCircle fontSize="large" />
			</IconButton>
			<StyledMenu
				id="customized-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<ListItem button component={RouterLink} to="/logout">
					<ListItemIcon>
						<ExitToAppIcon />
					</ListItemIcon>
					<ListItemText primary="Log Out" />
				</ListItem>
			</StyledMenu>
		</React.Fragment>
	);
}

export default UserProfileAppBar;
