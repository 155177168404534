import { SET_ALERT, CLEAR_ALERT } from './alertTypes';

export default (state, action) => {
	switch (action.type) {
		case SET_ALERT:
			return {
				...state,
				alert: action.payload
			};
		case CLEAR_ALERT:
			return {
				...state,
				alert: action.payload
			};
		default:
			return state;
	}
};
