import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import AuthContext from '../../context/authContext/authContext';
import DocumentContext from '../../context/documentContext/documentContext';
import AppBar from '../../components/appBar/AppBar';
import Loading from '../../components/common/Loading';
import GlobalStyle from '../common/GlobalStyle';
import SolutionSearch from '../../components/home/SolutionSearch';
import SearchResults from '../../components/home/SearchResults';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/Card';
import CardContent from '@material-ui/core/Card';
import { Typography } from '@material-ui/core';
import DocumentStyle from '../../components/document/DocumentStyle';
import Searching from '../../components/common/Searching';

const Dashboard = () => {
	const globalStyle = GlobalStyle();
	const documentStyle = DocumentStyle();

	const authContext = useContext(AuthContext);
	const documentContext = useContext(DocumentContext);
	const { isAuthenticated, isAuthorizedCheck, isAuthorized } = authContext;
	const { isSearching, searchResults, researchDocuments, documentContent, internalSolutionId } = documentContext;

	useEffect(
		() => {
			if (isAuthenticated && isAuthorized === null) {
				isAuthorizedCheck('All');
			}
		},
		[ isAuthenticated, isAuthorized, isSearching ]
	);

	if (isAuthenticated && isAuthorized && isSearching) {
		return (
			<React.Fragment>
				<AppBar />
				<div className={globalStyle.root}>
					<SolutionSearch />
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Searching />
						</Grid>
					</Grid>
				</div>
			</React.Fragment>
		);
	} else if (isAuthenticated && isAuthorized && (!isSearching || isSearching == null)) {
		return (
			<React.Fragment>
				<AppBar />
				<div className={globalStyle.root}>
					<SolutionSearch />
					{searchResults !== null ? (
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<Card>
									<CardHeader className={documentStyle.cardHeaderForDocumentsBlueBckg}>
										<Typography className={documentStyle.cardHeaderText} variant="h5">
											Solutions
										</Typography>
									</CardHeader>
									<CardContent>
										<SearchResults />
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					) : null}
				</div>
			</React.Fragment>
		);
	} else if (!isAuthenticated) {
		return <Redirect to="/logout" />;
	} else if (isAuthorized === null) {
		return <Loading />;
	} else if (isAuthenticated && !isAuthorized) {
		return <Redirect to="/NotAuthorized" />;
	} else {
		return <Redirect to="/logout" />;
	}
};

export default Dashboard;
