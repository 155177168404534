import React, { useContext, useEffect } from 'react';
import AlertContext from '../../context/alertContext/alertContext';
import Slide from '@material-ui/core/Slide';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
import { SnackbarProvider, useSnackbar } from 'notistack';
import FlagIcon from '@material-ui/icons/Flag';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import AlertStyle from './AlertStyle';
import moment from 'moment';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import { withStyles } from '@material-ui/core/styles';

const AlertDisplay = () => {
	const classes = AlertStyle();
	const alertContext = useContext(AlertContext);
	const { alert, clearAlert } = alertContext;

	//https://iamhosseindhv.com/notistack/demos#variants
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const ColorButton = withStyles((theme) => ({
		root: {
			color: '#fcfcfc',
			borderColor: '#fcfcfc',
			'&:hover': {
				color: '#242424',
				backgroundColor: '#fcfcfc'
			}
		}
	}))(Button);

	useEffect(
		() => {
			if (alert.title.length > 0) {
				const IconComponent = () => {
					switch (alert.type) {
						case 'success':
							return <AddAlertIcon className={classes.icon} />;
						case 'warning':
							return <FlagIcon className={classes.icon} />;
						case 'error':
							return <ErrorOutlineIcon className={classes.icon} />;
						default:
							return <AddAlertIcon className={classes.icon} />;
					}
				};

				const MessageComponent = () => {
					return (
						<React.Fragment>
							<Grid container className={classes.snackbarWidth}>
								<Grid container>
									<Grid item xs={12}>
										<Grid container>
											<Grid item xs={1}>
												<IconComponent />
											</Grid>
											<Grid item xs={11}>
												<Typography variant="h6" gutterBottom>
													{alert.title}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={12}>
									<Divider className={classes.divider} />
								</Grid>

								<Grid item xs={12} className={classes.snackbarWidth}>
									<Typography variant="body1" gutterBottom>
										{alert.message}
									</Typography>
								</Grid>
							</Grid>
						</React.Fragment>
					);
				};

				let variant = 'info';
				if (alert.type === 'warning') {
					variant = 'warning';
				} else if (alert.type === 'error') {
					variant = 'error';
				}

				const action = (key) => {
					return (
						<React.Fragment>
							<ColorButton
								onClick={() => {
									dismissAlert(key);
								}}
								size="small"
								variant="outlined"
								color="default"
							>
								Dismiss
							</ColorButton>
						</React.Fragment>
					);
				};

				const dismissAlert = (key) => {
					clearAlert();
					closeSnackbar(key);
				};

				enqueueSnackbar(<MessageComponent />, {
					preventDuplicate: true,
					variant: variant,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'center'
					},
					TransitionComponent: Slide,
					persist: false,
					action
				});
			}
		},
		[ alert ]
	);

	return null;
};

// export default AppBarComponent;
export default function IntegrationNotistack() {
	return (
		<SnackbarProvider hideIconVariant maxSnack={1}>
			<AlertDisplay />
		</SnackbarProvider>
	);
}
