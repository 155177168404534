import React, { useEffect, useContext, useState } from 'react';
import DocumentContext from '../../context/documentContext/documentContext';
import MaterialTable, { MTableToolbar } from 'material-table';
import PageTitle from './PageTitle';
import DocumentStyle from './DocumentStyle';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';

const DocumentTable = () => {
	const classes = DocumentStyle();
	const history = useHistory();
	const documentContext = useContext(DocumentContext);
	const { totalDocumentCount, isSearching, searchQuery, setIsProcessing, clearDocument } = documentContext;

	//const apiHost = 'http://localhost:5000';
	const apiHost = 'https://prod.api.microscopehc.solvaris.net';

	const [ state, setState ] = React.useState({
		columns: [
			{
				title: 'Id',
				field: 'Id',
				hidden: true
			},
			{
				title: 'Solution Id',
				field: 'solutionId',
				editable: 'never',
				width: '20%'
			},
			{
				title: 'File Name',
				field: 'fileName',
				editable: 'never',
				width: '20%'
			},
			{
				title: 'Description',
				field: 'description',
				editable: 'never',
				width: '20%',
				filtering: false
			},
			{
				title: 'Created',
				field: 'dateCreated',
				editable: 'never',
				width: '10%',
				filtering: false,
				render: (rowData) => <span>{moment(rowData['DateCreated']).format('MM/D/YY')}</span>
			},
			{
				title: 'Last Modified',
				field: 'lastModified',
				editable: 'never',
				width: '10%',
				filtering: false,
				render: (rowData) => <span>{moment(rowData['LastModified']).format('MM/D/YY')}</span>
			}
		],
		data: [
			{
				id: '',
				solutionId: '',
				fileName: '',
				description: '',
				dateCreated: '',
				lastModified: ''
			}
		]
	});

	useEffect(() => {}, [ isSearching, searchQuery, totalDocumentCount ]);

	// Setup config/headers and token
	const headerConfig = () => {
		// Headers
		const config = {
			headers: {
				'Content-type': 'application/json',
				'csrf-token': Cookies.get('XSRF-TOKEN'),
				Authorization: 'Bearer ' + localStorage.getItem('_mhcms')
			},
			withCredentials: true
		};

		return config;
	};

	const [ selectedRow, setSelectedRow ] = useState(null);

	const redirect = (id) => {
		setIsProcessing(null);
		clearDocument();
		history.push('/document/edit/' + id);
	};

	return (
		<React.Fragment>
			<PageTitle />
			<Grid className={classes.table}>
				<MaterialTable
					columns={state.columns}
					options={{
						showTitle: false,
						search: false,
						paging: true,
						pageSize: 10,
						filtering: true
					}}
					data={(query) =>
						new Promise((resolve, reject) => {
							let solutionId = '';
							let content = '';
							query.filters.forEach(function(filter) {
								if (filter.column.field == 'solutionId') {
									solutionId = filter.value;
								}
								if (filter.column.field == 'fileName') {
									content = filter.value;
								}
							});
							let body = {
								searchQuery: {
									solutionId,
									content
								},
								per_page: query.pageSize,
								page: query.page
							};
							axios.post(apiHost + '/document/tableResults/', body, headerConfig()).then((res) => {
								if (res.data.data.totalRecordCount > 0) {
									resolve({
										data: res.data.data.tableData,
										page: query.page,
										totalCount: res.data.data.totalRecordCount
									});
								} else {
									resolve({
										data: [],
										page: 0,
										totalCount: 0
									});
								}
							});
						})}
					components={{
						Toolbar: (props) => (
							<div style={{ display: 'none' }}>
								<MTableToolbar {...props} />
							</div>
						)
					}}
					onRowClick={(evt, selectedRow) => {
						setSelectedRow(selectedRow.tableData.id);
						redirect(selectedRow.id);
					}}
				/>
			</Grid>
		</React.Fragment>
	);
};
export default DocumentTable;
