import React, { useEffect, useState, useContext } from 'react';
import AuthContext from '../../context/authContext/authContext';
import AppBar from '../../components/appBar/AppBar';
import Alert from '../../components/alert/AlertDisplay';
import GlobalStyle from '../common/GlobalStyle';
import AlertContext from '../../context/alertContext/alertContext';
import DocumentContext from '../../context/documentContext/documentContext';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import CreateStyle from './CreateStyle';
import Paper from '@material-ui/core/Paper';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

const Create = () => {
	const globalStyle = GlobalStyle();
	const classes = CreateStyle();
	const history = useHistory();
	const authContext = useContext(AuthContext);
	const alertContext = useContext(AlertContext);
	const documentContext = useContext(DocumentContext);
	const { isAuthenticated, isAuthorizedCheck, isAuthorized, isProcessing } = authContext;
	const { setAlert } = alertContext;
	const {
		documentProcessing,
		uploadDocument,
		processingError,
		enableSubmitBtn,
		validateFileExtension,
		validFile,
		setIsProcessing
	} = documentContext;

	const [ form, setForm ] = useState({
		solutionId: '',
		fileName: '',
		description: ''
	});
	const { solutionId, fileName, description } = form;
	const onChange = (e) => {
		setForm({ ...form, [e.target.name]: e.target.value });
	};
	const [ file, setFile ] = useState('');

	useEffect(
		() => {
			if (isAuthenticated && isAuthorized === null && isProcessing == null) {
				isAuthorizedCheck('Admin Access');
			}
			if (isAuthorized && !isProcessing && processingError) {
				setAlert('Incorrect File Type', 'Please choose a .docx file to upload.', 'error', 5000);
			}
			if (documentProcessing === 'done') {
				history.push('/document');
			}
			if (validFile == false) {
				setAlert('Incorrect File Type', 'Please choose a .docx file to upload.', 'error', 5000);
			}
		},
		[ isAuthenticated, isAuthorized, isProcessing, documentProcessing, processingError, enableSubmitBtn, validFile ]
	);

	const [ uploadFileName, setUploadFileName ] = useState('Select Document');
	const onFileUploadChange = async (e) => {
		setFile(e.target.files[0]);
		setUploadFileName(e.target.files[0].name);
		validateFileExtension(e.target.files[0]);
	};

	const onSubmit = (e) => {
		e.preventDefault();
		const form = {
			solutionId,
			fileName,
			description
		};
		if (validFile == true && form.solutionId.length > 0 && form.fileName.length > 0) {
			setIsProcessing(true);
			uploadDocument(form, file);
		} else {
			setAlert('Required Field!', 'Solution Id, File Name and an uploaded file.', 'error', 5000);
		}
	};

	const cancel = () => {
		history.push('/document');
	};

	return (
		<React.Fragment>
			<AppBar />
			<Alert />
			<div className={globalStyle.root}>
				<Grid container spacing={1}>
					<Paper className={classes.rootTitle} elevation={3}>
						<Grid container>
							<Grid item xs={9}>
								<Typography variant="h5" gutterBottom>
									Add Document
								</Typography>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				<Paper className={classes.root} elevation={3}>
					<form onSubmit={onSubmit}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<TextField
									autoFocus
									required={true}
									type="number"
									className={classes.textFieldMedium}
									id="solutionId"
									label="Solution Id"
									name="solutionId"
									autoComplete="content"
									value={form.solutionId}
									onChange={onChange}
								/>
								<TextField
									required={true}
									type="text"
									className={classes.textFieldMedium}
									id="fileName"
									label="File Name"
									name="fileName"
									autoComplete="content"
									value={form.fileName}
									onChange={onChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={1}>
									<TextField
										required={false}
										type="text"
										multiline
										rows="5"
										className={classes.textFieldMedium}
										id="description"
										label="Description"
										name="description"
										autoComplete="content"
										value={form.description}
										variant="outlined"
										onChange={onChange}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<input
									className={classes.button}
									style={{ display: 'none' }}
									id="raised-button-file"
									name="uploadFileName"
									type="file"
									onChange={onFileUploadChange}
								/>
								<label htmlFor="raised-button-file">
									<Button variant="contained" component="span" className={classes.button}>
										{uploadFileName}
										<CloudUploadIcon className={classes.btnIcon} />
									</Button>
								</label>
								<Grid item xs={12} md={6}>
									<div>
										<Typography variant="caption" display="block" gutterBottom>
											*Supported file types: .docx
										</Typography>
									</div>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Divider className={classes.divider} />

								<Grid container spacing={1}>
									<Button
										variant="contained"
										size="medium"
										color="default"
										aria-label="cancel"
										className={classes.margin}
										onClick={() => cancel()}
									>
										Cancel
									</Button>
									{documentProcessing && <CircularProgress />}
									{documentProcessing == null && (
										<Button
											className={classes.margin}
											size="medium"
											type="submit"
											variant="contained"
											color="primary"
										>
											Add Document
										</Button>
									)}
								</Grid>
							</Grid>
						</Grid>
					</form>
				</Paper>
			</div>
		</React.Fragment>
	);
};
export default Create;
