export const SET_LOADING = 'SET_LOADING';
export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED';
export const SET_IS_PROCESSING = 'SET_IS_PROCESSING';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SET_LOGIN_EMAIL = 'SET_LOGIN_EMAIL';
export const SET_AUTHORIZATION = 'SET_AUTHORIZATION';
export const SET_AUTH_MEMBER = 'SET_AUTH_MEMBER';
export const SET_IS_VERIFYING_AUTHENTICATION = 'SET_IS_VERIFYING_AUTHENTICATION';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const IS_SETTING_ALERT = 'IS_SETTING_ALERT';
