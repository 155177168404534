import React, { useContext } from 'react';
import SearchInput from './SearchInput';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Title from '../common/Title';
import Typography from '@material-ui/core/Typography';
import SolutionSearchStyle from './SolutionSearchStyle';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const SolutionSearch = () => {
	const classes = SolutionSearchStyle();

	return (
		<React.Fragment>
			<Paper className={classes.searchPaper}>
				<Grid container spacing={1}>
					<Grid item xs={12} lg={5}>
						<Title>Solution Search</Title>
						<SearchInput />
					</Grid>
					<Grid item xs={12} lg={7}>
						<List
							component="nav"
							aria-labelledby="nested-list-subheader"
							subheader={
								<ListSubheader className={classes.listSubHeaderCustom} id="nested-list-subheader">
									Search Tips:
								</ListSubheader>
							}
							className={classes.root}
						>
							<ListItem>
								<Typography
									variant="caption"
									className={classes.listItemCustom}
									id="nested-list-subheader"
								>
									Exact Text Search: Surround text with quotes. Example: "Most disposable hospital"
								</Typography>
							</ListItem>
							<ListItem>
								<Typography
									variant="caption"
									className={classes.listItemCustom}
									id="nested-list-subheader"
								>
									* Operator: End partial words with an asterisk to search by partial text. Example:
									hospit*
								</Typography>
							</ListItem>
							<ListItem>
								<Typography
									variant="caption"
									className={classes.listItemCustom}
									id="nested-list-subheader"
								>
									+ Operator: Stipulates that a document must contain both terms. Example: hospital +
									disposable
								</Typography>
							</ListItem>
							<ListItem>
								<Typography
									variant="caption"
									className={classes.listItemCustom}
									id="nested-list-subheader"
								>
									| Operator: An OR operation that stipulates the search may return either terms.
									Example: hospital | disposable
								</Typography>
							</ListItem>
							<ListItem>
								<Typography
									variant="caption"
									className={classes.listItemCustom}
									id="nested-list-subheader"
								>
									- Operator: A NOT operation that matches on documents that exclude the term.
									Example: hospital - termToExclude
								</Typography>
							</ListItem>
						</List>
					</Grid>
				</Grid>
			</Paper>
		</React.Fragment>
	);
};
export default SolutionSearch;
