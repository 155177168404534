export const SET_LOADING = 'SET_LOADING';
export const SET_IS_PROCESSING = 'SET_IS_PROCESSING';
export const SET_PROCESSING_SUCCESS = 'SET_PROCESSING_SUCCESS';
export const SET_PROCESSING_ERROR = 'SET_PROCESSING_ERROR';
export const SET_VISIBLE_COMPONENT = 'SET_VISIBLE_COMPONENT';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_SELECTED_ID = 'SET_SELECTED_ID';
export const SET_INITIALIZING = 'SET_INITIALIZING';
export const SET_TOTAL_RECORD_COUNT = 'SET_TOTAL_RECORD_COUNT';
export const SET_DOCUMENT = 'SET_DOCUMENT';
export const SET_ENABLE_BUTTON = 'SET_ENABLE_BUTTON';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_RESEARCH_DOCUMENTS = 'SET_RESEARCH_DOCUMENTS';
export const SET_SOLUTION_ID = 'SET_SOLUTION_ID';
export const SET_INTERNAL_SOLUTION_ID = 'SET_INTERNAL_SOLUTION_ID';
export const SET_IS_SEARCHING = 'SET_IS_SEARCHING';
export const SET_DOCUMENT_CONTENT = 'SET_DOCUMENT_CONTENT';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SET_IS_DOWNLOADING = 'SET_IS_DOWNLOADING';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const SET_VALID_FILE = 'SET_VALID_FILE';
