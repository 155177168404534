import React, { useContext } from 'react';
import AuthContext from '../../context/authContext/authContext';
import AdminTools from './AdminTools';
import DrawerComponentStyle from './DrawerComponentStyle';
import Divider from '@material-ui/core/Divider';
import MicroscopHCLogo from '../../images/microscopeHC_Logo.png';
import MainListItems from './MainListItems';
import Link from '@material-ui/core/Link';
import { useHistory } from 'react-router-dom';

function DrawerComponent() {
	const classes = DrawerComponentStyle();
	const authContext = useContext(AuthContext);
	const { authMember } = authContext;
	const history = useHistory();

	const redirect = () => {
		history.push('/');
	};

	return (
		<div>
			<div className={classes.toolbar} />
			<Link href="#" onClick={() => redirect()}>
				<img src={MicroscopHCLogo} className={classes.logo} alt="Logo" />
			</Link>
			<Divider />
			<MainListItems />
			<Divider />
			{/*
			{authMember.role === 'Admin' && (
				<React.Fragment>
					<AdminTools />
				</React.Fragment>
			)}
			 */}
		</div>
	);
}

export default DrawerComponent;
