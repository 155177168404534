import React, { useContext } from 'react';
import AuthContext from '../../context/authContext/authContext';
import PeopleIcon from '@material-ui/icons/People';
import BuildIcon from '@material-ui/icons/Build';
import BugReportIcon from '@material-ui/icons/BugReport';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Link } from 'react-router-dom';
import GlobalStyle from '../common/GlobalStyle';

function AdminTools() {
	const globalStyle = GlobalStyle();
	const authContext = useContext(AuthContext);
	const { authMember } = authContext;

	if (authMember.role == 'Admin') {
		return (
			<List>
				<ListSubheader className={globalStyle.listSubHeader} inset>
					Admin Tools
				</ListSubheader>
				<ListItem button component={Link} to="/users">
					<ListItemIcon>
						<PeopleIcon />
					</ListItemIcon>
					<ListItemText primary="Users" />
				</ListItem>
				<ListItem button component={Link} to="/roles">
					<ListItemIcon>
						<BuildIcon />
					</ListItemIcon>
					<ListItemText primary="Roles" />
				</ListItem>
				<ListItem button component={Link} to="/logs">
					<ListItemIcon>
						<BugReportIcon />
					</ListItemIcon>
					<ListItemText primary="Logs" />
				</ListItem>
			</List>
		);
	} else {
		return null;
	}
}

export default AdminTools;
