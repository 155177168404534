import { makeStyles } from '@material-ui/core/styles';

const globalStyle = makeStyles((theme) => ({
	root: {
		marginTop: 58,
		marginLeft: 0,
		[theme.breakpoints.up('sm')]: {
			marginLeft: 240
		},
		padding: theme.spacing(3, 2)
	},
	loading: {
		marginTop: 58,
		marginLeft: 0,
		padding: theme.spacing(3, 2)
	},
	listSubHeader: {
		paddingLeft: 20,
		color: '#3E444D'
	}
}));

export default globalStyle;
