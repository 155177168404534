import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from '../../context/authContext/authContext';
import AppBar from '../../components/appBar/AppBar';
import GlobalStyle from '../common/GlobalStyle';
import Loading from '../../components/common/Loading';

const PrivateRoute = ({ component: Component, ...rest }) => {
	const globalStyle = GlobalStyle();
	const authContext = useContext(AuthContext);
	const { isAuthenticated, setIsAuthenticated, setAuthenticationLoading, isAuthenticationLoading } = authContext;

	useEffect(
		() => {
			if (isAuthenticated === null) {
				setAuthenticationLoading(true);
				setIsAuthenticated();
			} else if (isAuthenticated && isAuthenticationLoading) {
				setAuthenticationLoading(false);
			} else if (!isAuthenticated) {
				setAuthenticationLoading(false);
			}
		},
		[ isAuthenticated, isAuthenticationLoading ]
	);

	if (!isAuthenticationLoading && isAuthenticated) {
		return <Route {...rest} render={(props) => <Component {...props} />} />;
	} else if (isAuthenticationLoading === null && isAuthenticated === null) {
		return (
			<React.Fragment>
				<AppBar />
				<div className={globalStyle.loading}>
					<Loading />
				</div>
			</React.Fragment>
		);
	} else if (!isAuthenticationLoading && !isAuthenticated) {
		return <Redirect to="/login" />;
	} else {
		return (
			<React.Fragment>
				<AppBar />
				<div className={globalStyle.loading}>
					<Loading />
				</div>
			</React.Fragment>
		);
	}
};

export default PrivateRoute;
