import React, { useContext, useEffect } from 'react';
import SearchingStyle from './SearchingStyle';
import MicroscopeHC_Logo from '../../images/microscopeHC_Logo.png';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const Searching = () => {
	const classes = SearchingStyle();

	return (
		<div>
			<Container component="main" maxWidth="xs">
				<div className={classes.paper}>
					<div className="loginIcon">
						<img src={MicroscopeHC_Logo} className={classes.logo} alt="Logo" />
					</div>
					<Typography component="h1" variant="h5">
						Searching
					</Typography>
					<br />
					<CircularProgress />
				</div>
			</Container>
		</div>
	);
};

export default Searching;
