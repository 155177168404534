import React, { useContext } from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';

function MainListItems() {
	const getLink = (component) => {
		return 'https://marginsolutions.org/' + component;
	};
	const getAdminSiteLink = () => {
		return 'https://app.thesevenexgroup.com/AppAdmin/';
	};

	return (
		<List>
			<ListItem button component="a" href={getLink('')}>
				<ListItemIcon>
					<FindInPageIcon />
				</ListItemIcon>
				<ListItemText primary="Solution Search" />
			</ListItem>
			<ListItem button component="a" href={getLink('document')}>
				<ListItemIcon>
					<MenuBookIcon />
				</ListItemIcon>
				<ListItemText primary="Documents" />
			</ListItem>
			<ListItem button component="a" href={getAdminSiteLink()}>
				<ListItemIcon>
					<DesktopMacIcon />
				</ListItemIcon>
				<ListItemText primary="Admin Site" />
			</ListItem>
		</List>
	);
}

export default MainListItems;
