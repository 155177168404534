import { makeStyles } from '@material-ui/core/styles';

const alertStyle = makeStyles((theme) => ({
	snackbarWidth: {
		minWidth: 700,
		maxWidth: 700
	},
	icon: {
		marginTop: 5
	},
	divider: {
		marginTop: 0,
		marginBottom: 5,
		marginLeft: 0,
		marginRight: 0,
		background: '#fff'
	}
}));

export default alertStyle;
