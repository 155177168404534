import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import AuthContext from '../../context/authContext/authContext';
import AppBar from '../../components/appBar/AppBar';
import Loading from '../../components/common/Loading';
import GlobalStyle from '../common/GlobalStyle';
import DocumentTable from './DocumentTable';

const Document = () => {
	const globalStyle = GlobalStyle();
	const authContext = useContext(AuthContext);
	const { isAuthenticated, isAuthorizedCheck, isAuthorized, isProcessing } = authContext;

	useEffect(
		() => {
			if (isAuthenticated && isAuthorized === null && isProcessing == null) {
				isAuthorizedCheck('Admin Access');
			}
		},
		[ isAuthenticated, isAuthorized, isProcessing ]
	);

	if (isProcessing == null && isAuthorized == null) {
		return <Loading />;
	} else if (isProcessing == true) {
		return <Loading />;
	} else if (isAuthenticated && isAuthorized && !isProcessing) {
		return (
			<React.Fragment>
				<AppBar />
				<div className={globalStyle.root}>
					<DocumentTable />
				</div>
			</React.Fragment>
		);
	} else if (!isAuthenticated) {
		return <Redirect to="/logout" />;
	} else if (isAuthenticated && !isAuthorized) {
		return <Redirect to="/NotAuthorized" />;
	} else {
		return <Loading />;
	}
};

export default Document;
