import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Context
import AlertState from './context/alertContext/AlertState';
import AuthState from './context/authContext/AuthState';
import DocumentState from './context/documentContext/DocumentState';

// Components
import Login from './components/auth/Login';
import Logout from './components/auth/Logout';
import PrivateRoute from './components/auth/PrivateRoute';
import Dashboard from './components/home/Dashboard';
import Document from './components/document/Document';
import DocumentAdd from './components/document/Create';
import DocumentEdit from './components/document/Edit';
import DocumentContentComponent from './components/document/DocumentContentComponent';

// Material UI
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const App = () => {
	const breakpoints = createBreakpoints({});

	const theme = createMuiTheme({
		palette: {
			primary: {
				main: '#666666',
				contrastText: '#F3F5F7'
			},
			secondary: {
				main: '#2f94bf',
				contrastText: '#F3F5F7'
			}
		},
		overrides: {
			MuiTab: {
				root: {
					[breakpoints.up('md')]: {
						'min-width': '140px'
					}
				}
			}
		}
	});

	return (
		<MuiThemeProvider theme={theme}>
			<AuthState>
				<AlertState>
					<Router>
						<Fragment>
							<Switch>
								<Route exact path="/login" component={Login} />
								<Route exact path="/logout" component={Logout} />

								<PrivateRoute exact path="/">
									<DocumentState>
										<Dashboard />
									</DocumentState>
								</PrivateRoute>

								<PrivateRoute exact path="/document/">
									<DocumentState>
										<Document />
									</DocumentState>
								</PrivateRoute>

								<PrivateRoute exact path="/document/add/">
									<DocumentState>
										<DocumentAdd />
									</DocumentState>
								</PrivateRoute>

								<PrivateRoute exact path="/document/edit/:id">
									<DocumentState>
										<DocumentEdit />
									</DocumentState>
								</PrivateRoute>

								<PrivateRoute exact path="/document/content">
									<DocumentState>
										<DocumentContentComponent />
									</DocumentState>
								</PrivateRoute>
							</Switch>
						</Fragment>
					</Router>
				</AlertState>
			</AuthState>
		</MuiThemeProvider>
	);
};
export default App;
